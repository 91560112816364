import axios from "../utils/request";

//设备控制列表
export function getList(data) {
  return axios({
    url: "/clodchainAM/web/get_device_control_list_page",
    method: "get",
    params: data
  });
}
