<template>
  <div>
    <div class="condition">
      <el-input
        class="condition_input content_item"
        placeholder="请输入设备码或关键字"
        v-model="deviceCode"
        clearable
      ></el-input>
      <el-select
        v-model="controlResult"
        class="content_state content_item"
        placeholder="请选择控制结果"
        clearable
      >
        <el-option
          v-for="item in stateItme"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <span class="warning_time_title">处理时间</span>
      <el-date-picker
        prefix-icon="el-icon-date"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        v-model="startTime"
        type="datetime"
        placeholder="选择开始日期时间"
      >
      </el-date-picker>
      <span style="padding:4px">-</span>
      <el-date-picker
        class="content_item"
        prefix-icon="el-icon-date"
        v-model="endTimes"
        value-format="yyyy-MM-dd HH:mm:ss"
        format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        placeholder="选择结束日期时间"
      >
      </el-date-picker>
      <el-button class="query" @click="Query()">查询</el-button>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="controlId" label="控制命令" width="180">
        </el-table-column>
        <el-table-column prop="controlContent" label="命令内容" width="180">
        </el-table-column>
        <el-table-column label="控制结果">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.controlResult == 1"
              >成功</el-tag
            >
            <el-tag type="warning" v-if="scope.row.controlResult == 2"
              >失败</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="deviceImei" label="IMEI"> </el-table-column>
        <el-table-column prop="resultContent" label="组织反馈内容">
        </el-table-column>
        <el-table-column prop="createTime" label="命令下发时间">
        </el-table-column>
        <el-table-column prop="resultTime" label="模块反馈时间">
        </el-table-column>
      </el-table>
    </div>
    <div class="app-page-container">
      <span @click="first()">首页</span>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="pager"
        :hide-on-single-page="false"
        :page-size="12"
        :current-page.sync="currentPage"
        :total="recordCount"
      >
      </el-pagination>
      <span @click="last()">尾页</span>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/equipmentControl.js";
export default {
  name: "index",
  data() {
    return {
      pages: 1,
      pageSize: 12, //每页数据条数
      recordCount: 0,
      currentPage: 1, //当前页数
      deviceCode: "",
      state: "",
      startTime: "",
      endTimes: "",
      organizationName: "",
      deepId: "",
      tableData: [],
      status: "",
      controlResult: "",
      stateItme: [
        {
          value: "1",
          label: "成功"
        },
        {
          value: "2",
          label: "失败"
        }
      ]
    };
  },
  created() {
    this.Query();
  },
  methods: {
    //获取数据列表
    Query() {
      let data = {
        userId: localStorage.getItem("userId"),
        token: localStorage.getItem("token"),
        deptId: localStorage.getItem("deptId"), //部门ID
        curPage: this.pages,
        limit: 12,
        deviceImei: this.deviceCode,
        startCreateTime: this.startTime,
        endCreateTime: this.endTimes,
        controlResult: this.controlResult
      };

      getList(data).then(res => {
        this.tableData = res.data.data.records;
        this.recordCount = res.data.data.total;
        console.log(this.tableData);
      });
    },

    handleCurrentChange(val) {
      this.pages = val;
      this.Query();
      console.log(`当前页: ${val}`);
    },
    //分页首页
    first() {
      this.currentPage = 1;
      this.pages = 1;
      this.Query();
    },
    //分页最后一页
    last() {
      this.currentPage = this.recordCount;
      this.pages = Math.ceil(this.recordCount / 12);
      this.Query();
    }
  }
};
</script>

<style scoped>
/*分页样式*/
.app-page-container {
  margin-top: 20px;
}
.app-page-container .el-pagination {
  display: inline-block;
}
.app-page-container span {
  cursor: pointer;
  color: #616161;
  background: #fff;
  border: 1px solid #e8eaf0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  text-align: center;
  transition: 0.1s;
  vertical-align: top;
  border-radius: 16px;
  font-size: 12px;
  width: 56px;
}
.app-page-container span:hover {
  background: #00cccc;
  color: #fff;
  border-color: #00cccc;
}
.edit {
  font-size: 14px;
  color: #6e7790;
}

.edit:hover {
  color: #0cc;
}
.table {
  margin-top: 20px;
}
.warning_time_title {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #3d3d3d;
  letter-spacing: -0.04px;
  margin-right: 5px;
}
.content_item {
  margin-right: 10px;
}
.content_state {
  width: 150px;
}
.condition_input {
  width: 246px;
  height: 42px;
}
</style>
